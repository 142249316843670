import React from "react";
import Image from "next/image";

export default function NodeClientList({ nodes }) {
  const list = [...nodes, ...nodes];

  return (
    <div className="relative overflow-hidden h-48">
      <div className="absolute top-1/2 left-0 -translate-y-1/2">
        <div className="flex will-change-transform animate-scroll backface-hidden w-max lg:hover:animation-state-pause">
          {list.map((node, index) => {
            const key = `${node.id}-${index}`;
            const hasLink = node.field_link?.url || false;
            const logo = (
              <Image
                src={node.field_image.image_style_uri.small_logo}
                alt={node.field_image.resourceIdObjMeta.alt}
                className="object-contain grayscale hover:grayscale-0 transition-all duration-300"
                fill
              />
            );

            return (
              <div key={key} className="px-8">
                <figure className="relative w-40 h-40">
                  {hasLink ? (
                    <a
                      href={node.field_link.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {logo}
                    </a>
                  ) : (
                    logo
                  )}
                </figure>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
