import NodeClientList from "components/Client/NodeClientList";
import React from "react";

export default function ClientsSection({ nodes }) {
  return (
    <section className="relative mb-16 2xl:mb-28">
      <h2 className="sr-only">Nos clients</h2>
      <NodeClientList nodes={nodes} />
    </section>
  );
}
